import { Box, Button, Image, Input, Text, useToast } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import logo from "../assets/mobileOtp.svg"

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import MobileOTPVerify from './MobileOTPVerify';

import {auth} from "../firebase";
import { RecaptchaVerifier , signInWithPhoneNumber } from "firebase/auth";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../Context/AppContext';
import "../App.css"


function LoginMobileOTP() {
    const [otp,setOtp] = useState("");
    const [ph,setPh] = useState("");

    const [showOTP,setShowOTP] = useState(false);
    const [user,setUser] = useState(null);

    const [loading,setLoading] = useState(false);
    const [loading2,setLoading2] = useState(false);
    const toast = useToast();

    const navigate = useNavigate()
    const authFunction = useContext(AppContext)

    function onCaptchVerify(){
        if(!window.recaptchaVerifier){
            const recaptchaContainer = document.getElementById('recaptcha-container');
            window.recaptchaVerifier = new RecaptchaVerifier(auth, recaptchaContainer, {
            'size': 'normal',
            'callback': (response) => {
              onSignup()
            },
            'expired-callback': () => {
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
            }
          });
      }
    }

    function onSignup(){
        setLoading(true)
        onCaptchVerify()

        const appVerifier = window.recaptchaVerifier

        const formatPh = '+' + ph
        signInWithPhoneNumber(auth,formatPh,appVerifier)
        .then((confirmationResult) => {
            console.log(confirmationResult)
            window.confirmationResult = confirmationResult
            toast({
                title: `OTP Sent on Your ${ph}!`,
        
                status: "success",
        
                isClosable: true,
                position: "top",
              });
            setShowOTP(true)
            setLoading(false)
        })
        .catch((error) => {
            console.log(error)
            toast({
                title: error.message,
        
                status: "error",
        
                isClosable: true,
                position: "top",
              });
            setLoading(false)
        })
    }

    console.log(auth)

    function onOTPVerify() {
        setLoading2(true)
        window.confirmationResult
          .confirm(otp)
          .then(async (res) => {
            console.log(res);
      
            // Make a POST request to your backend with the user data
            const userData = {
              name: res.user.displayName,
              email: res.user.email,
              phone:res.user.phoneNumber,
              userId: res.user.uid,
            };
      
            try {
              const response = await axios.post('https://interiorme.onrender.com/user/mobileOTP', userData);
              console.log(response);
              
              localStorage.setItem("token",response.data.token)
              localStorage.setItem("role",response.data.user.role)
              authFunction.handleLogin(response.data.token)
                navigate("/userprofile")
                // window.location.reload()
            } catch (error) {
              console.error('Error posting user data:', error);
            }
            toast({
              title: 'Login Success!',
              status: 'success',
              isClosable: true,
              position: 'top',
            });
            setUser(res.user);
            setLoading2(false)
          })
          .catch((error) => {
            console.log(error);
            toast({
                title: error.message,
                status: 'error',
                isClosable: true,
                position: 'top',
              });
            setLoading2(false)
          });
      }

  return (
    <div>
        {showOTP ?
        <>
        <MobileOTPVerify loading2={loading2} otp={otp} setOtp={setOtp} onOTPVerify={onOTPVerify} ph={ph}  />
        </>
        :
        <>
            <Box p={"20px 20px 70px 20px"}>
            <Box w={{base:"90%",lg:"30%"}} m={"auto"} p={"50px 60px 50px 60px"} borderRadius={"20px"} boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"}>
                <Box>
                    <Text fontSize={{base:"15px",lg:"20px"}} color={"#5c727d"} fontWeight={500}>Create Account</Text>
                </Box>
                <Box p={"30px"}>
                    <Image m={"auto"} w={"100%"} src={logo} />
                </Box>
                <Box>
                    <Text fontSize={{base:"10px",lg:"16px"}} fontWeight={500} mb={"20px"}>Enter your mobile number to Create Account.</Text>
                </Box>
                <Box>
                    <Text fontSize={{base:"10px",lg:"16px"}} fontWeight={500} mb={"20px"} color={"grey"}>We will send you one time password (OTP)</Text>
                </Box>
                <Box pl={{base:"10px",lg:"10px"}} w={{base:"100%",lg:"100%"}}>
                    <PhoneInput containerStyle={{
                      width:'100%'
                    }} inputStyle={{
                      width: '100%',
                      fontSize:"10px"
                  }} country={'in'} value={ph} onChange={setPh} />
                </Box>
                <Box mt={"10px"}>
                    <Button isLoading={loading} onClick={onSignup} size={{base:"sm",lg:""}} bg={"#f20505"} color={"white"} p={{base:"10px 30px 13px 30px",lg:"10px 30px 10px 30px"}} borderRadius={"20px"}>Send</Button>
                </Box>
            </Box>
            <Box pl={{base:"20px",lg:"0px"}} p={{base:"",lg:"70px 70px 70px 150px"}} mt={"20px"} m={"auto"} display={"flex"} justifyContent={{base:"",lg:"center"}}>
              <div style={{width:"30%"}} id='recaptcha-container'></div>
            </Box>
        </Box>
        </>
        }
    </div>
  )
}

export default LoginMobileOTP