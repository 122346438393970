import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

function TermsConditions() {
  return (
    <Box p={"20px 0px 160px 0px"}>
      <Text fontWeight={"bold"} fontSize={"40px"}>
        Terms and Conditions
      </Text>
      <Box w={"75%"} m={"auto"} textAlign={"left"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
          HMM Tech Private Limited, having its physical office address at 102
          Gahlan Apartment, UGF, Plot no 38 & 39, Khno 27/24, Mohit Nagar,
          Kakrola, Sector 15, Dwarka, New Delhi - 110078 (hereinafter also
          referred to as{" "}
          <span style={{ fontWeight: "bold", letterSpacing: "1px" }}>
            “Company”, “Homminterior,” “we”, “our” or “us”)
          </span> {" "}
          operates {" "}
          <a
            href="/"
            target="_blank"
            style={{ color: "blue", fontWeight: "normal" }}
          >
            www.homminterior.com
          </a>{" "}
          including the related mobile site (hereinafter referred to as
          “Website”). The term “Service” means the Website operated by
          Homminterior.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
          This document is an electronic record in terms of the Information
          Technology Act, 2000 and rules there under as applicable and the
          amended provisions pertaining to electronic records in various
          statutes as amended by the Information Technology Act, 2000. This
          electronic record is generated by a computer system and does not
          require any physical or digital signatures.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
          This document is published in accordance with the provisions of Rule 3
          (1) of the Information Technology (Intermediaries guidelines) Rules,
          2011 that require publishing the rules and regulations, privacy policy
          and Terms and Conditions for access or usage of domain name {" "}
          <a
            href="/"
            target="_blank"
            style={{ color: "blue", fontWeight: "normal" }}
          >
            www.homminterior.com
          </a>
          , including the related mobile site.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
          If these Terms are modified in any way, they will be updated here.
          Regularly checking and reviewing this page ensures that you are
          updated. If we believe that the modifications are material, we will
          notify you of the changes by posting a notice on the Website, or
          emailing you at the email address provided to us by you, and as we may
          deem appropriate. What constitutes a material change will be
          determined by us, at our sole and absolute discretion.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
          These Terms and Conditions (“Terms”) govern the use of the Services
          provided by Homminterior. By accessing and/or otherwise using the
          Services in any manner, you, therefore, agree that you have read and
          accepted these Terms. We reserve the right to modify these Terms,
          without notice, at any time. You understand that your continued use of
          the Services after these Terms have been modified constitutes your
          acceptance of these Terms as amended.
        </Text>
      </Box>

      <Box pt={"70px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
          SECTION 1: AGREEMENT TO TERMS
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
          By accessing this Website, you agree to be bound by the same and
          acknowledge that it constitutes an agreement between you and the
          Company (hereinafter the “User Agreement”). You may not use the
          Services if you do not accept the Terms or are unable to be bound by
          the Terms. Your use of the Website is at your own risk, including the
          risk that you might be exposed to content that is otherwise
          inappropriate.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        The terms ‘user(s)’, “your” and ‘you’ hereunder refer to all persons visiting, accessing,
        browsing through and/or using the Website at any point in time.        
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 2: REGISTRATION, ACCOUNTS, AND PASSWORDS
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        A user(s) is required to create an account by using a social media account or email and password
        and providing the necessary details about them in order to use our Services.        
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
          It’s important that you provide us with accurate, complete and up-to-date information for your
          Account and you agree to update such information, as needed, to keep it accurate, complete and
          up-to-date. If you don’t, we might have to suspend or terminate your Account. You agree that
          you won’t disclose your Account password to anyone and you’ll notify us immediately of any
          unauthorized use of your Account. user(s) are responsible for the personal protection and
          security of any password or email you may use to access the Website. You are responsible for all
          activity conducted on the Website that can be linked or traced back to your email or password.
          You are obligated to immediately report a lost or stolen password or email to our Customer
          Service. If you believe someone has used your password or account without your authorization,
          you must notify Customer Service immediately.       
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Homminterior shall not be responsible in any manner whatsoever for the authenticity of the
        personal information or sensitive personal data or information supplied by the user to the
        Company or to any other person acting on behalf of the Company.               
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        You are prohibited from misrepresenting Your identity and agree not to represent Yourself as
        another user or login/ register using the identity of any other Person. You are responsible to
        maintain and promptly update the information provided while signing up or verifying or for any
        other purpose on the Website to ensure that the information provided by You is true, accurate,
        current, complete and not misleading at all times.               
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        If You provide any information that is untrue, inaccurate, misleading, not current or incomplete
        or the Company has reasonable grounds to believe that such information is untrue, inaccurate,
        misleading, not current or incomplete, or not in accordance with this Agreement, the Company
        reserves the right to indefinitely suspend or terminate or block Your use or access to the Website
        in any manner whatsoever. Should any other user or person act upon such untrue, inaccurate, not
        current or incomplete information provided or verified by You, the Company and its Personnel
        shall not be liable for any damages, Losses, direct, indirect, immediate or remote, interests or
        claims resulting from such information to You or to any third party. You hereby agree and
        undertake to indemnify and hold harmless the Company, and its personnel in accordance with the
        indemnity clause contained in these Terms.
               
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        We reserve the right to access and disclose any information, including user names of accounts,
        and other information, to comply with applicable laws.               
        </Text>
      </Box>


      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 3: SERVICES
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Homminterior provides a wide range of Services including the listing of third-party service
        providers such as freelancers, professionals, and local workers on the Website (“Service
        Provider”). Homminterior does not directly employ these Service Providers and does not
        guarantee their availability or suitability for specific projects.
               
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        SERVICE PROVIDERS ARE SOLELY RESPONSIBLE FOR THE SERVICES THEY OFFER
        OR PROVIDE THROUGH THE WEBSITE. HOMMINTERIOR DO NOT EMPLOY THE
        SERVICE PROVIDERS. IN CASE OF ANY SERVICE-RELATED ISSUES, SERVICE
        PROVIDERS OR CUSTOMERS ARE NOT AUTHORIZED TO BIND THE COMPANY OR
        ITS REPRESENTATIVES.  
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 4: ELIGIBILITY CONDITIONS
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        In order to use the Website, You need to be 18 (eighteen) years of age or older in accordance
        with the Indian Contract Act, of 1872 and the Indian Majority Act, of 1875. The Website may
        only be used or accessed by such Persons who can enter into and perform legally binding
        contracts under the applicable laws. Homminterior shall not be liable in case any false
        information is provided by the user including the user’s age and the user and/or his natural or
        appointed guardian alone shall be liable for the consequences as per the applicable laws.
  
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Homminterior disclaims all liability arising out of such unauthorised use of the Website and any
        third-party liability arising out of Your use of the Website if You are a minor.
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 5: ELECTRONIC COMMUNICATION
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
          By using the Website, you understand that we may send you communications (including text
          messages and electronic mail) or data regarding our Services. You agree to receive such
          communications from us.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Unless such email is necessary to provide you with information related to a Service, we will give
        you the opportunity to opt-out of receiving these commercial emails from us by following the
        opt-out instructions provided in such message(s). Opting out may prevent you from receiving
        email messages regarding our services, promotions, marketing, special offers, improvements, or
        other updates. 
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Users may provide their email addresses to us in order to receive communication from us, such
        as newsletters, promotional offers, updates, and other marketing material. By providing us with
        their email address, users consent to receive such communications from us, including
        promotional emails, offers, and updates. Users may opt-out of receiving these communications at
        any time by following the unsubscribe instructions included in the email.        
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        We may also use email to communicate with users about their accounts, services, or other
        non-promotional matters related to our website. Users agree that any email communication sent
        by us to the email address provided by them shall be deemed to have been received by them.        
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Users are responsible for ensuring that the email address provided to us is accurate and
        up-to-date. We shall not be liable for any damages or losses arising from any failure by the user
        to provide an accurate or up-to-date email address.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Users acknowledge that email communications may not be secure and may be subject to
        interception, unauthorized access, or alteration. We shall not be liable for any damages or losses
        arising from any interception, unauthorized access, or alteration of email communications sent to
        or received from users.

        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        You also agree that all notices, disclosures, agreements, and other communications that we
        provide to you by email satisfy any legal requirement that such communications be in writing.
        Your consent to receive communications and do business by email, and our agreement to do so,
        applies to all of your interactions with the Company. Please keep us informed of any changes in
        your email address so you may continue to receive our communications without interruption.
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 6: ACCURACY OF THE INFORMATION
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        The information provided on our website is intended for general informational purposes only.
        While we strive to ensure the accuracy and reliability of the information, we make no
        representations or warranties of any kind, express or implied, regarding the completeness,
        accuracy, reliability, suitability, or availability of the information.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Users acknowledge that any reliance on the information provided on our website is at their own
        risk. We disclaim all liability for any inaccuracies, errors, or omissions in the information and for
        any actions taken based on the information available on our website.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Users are encouraged to independently verify the accuracy and completeness of any information
        obtained from our website before relying on it. They should consult appropriate professionals or
        experts for specific advice or information related to their particular circumstances.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        We reserve the right to modify, update, or remove any information on our website at any time
        without prior notice. However, we do not guarantee that the information on our website will
        always be up-to-date or free from errors. Also, We have no obligation to update any information
        on our Website.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Users are responsible for ensuring the accuracy and truthfulness of any information they provide
        to us through our website, including during the registration or account creation process. We shall
        not be liable for any damages or liabilities arising from inaccurate or misleading information
        provided by users. You agree that it is your responsibility to monitor changes to our Website.
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 7: MODIFICATIONS TO THE PRICE AND SERVICE
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
          We reserve the right at any time to modify or discontinue the Service (or any part or content
          thereof) without notice at any time. We shall not be liable to you or to any third party for any
          modification, price change, suspension, or discontinuance of the Service.          
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
          Platform fees that the Service Provider pays are final and non-refundable unless determined by
          Homminterior or required by applicable law. Homminterior reserves the right to amend the
          platform fees at any time at its sole discretion.          
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 8: LICENSE
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        By using this Website, you are granted a limited, non-exclusive, non-transferable right to use the
        content and materials on the Website in connection with your personal, non-commercial use of
        the Website. You may not copy, reproduce, transmit, distribute, or create derivative works of
        such content or information without express written authorization from the Company or the
        applicable third party (if third-party content is at issue).                  
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 9: NO ENDORSEMENT
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        This Website may contain links to other Web sites or references to other Service Providers.
        Homminterior does not assume responsibility for the accuracy or the appropriateness of the
        information contained at such other sites nor does it endorse the services advertised or sold
        thereon or provided by any companies or other Service Providers mentioned in this Website.                  
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 10: USER’S CONDUCT
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        The Customer undertakes that it shall conduct itself in accordance with all applicable laws, in a
        respectful, courteous manner, and allow Service Providers a safe and conducive environment to
        provide the Services. The Customer agrees that it will be solely responsible, (a) to ensure its own
        safety and security, and that of the Service Providers: (b) not to undertake or assist in any
        unlawful, immoral or illegal activity (c) for any unauthorised use of the Website whilst using the
        Website.      
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        In the event that you fail to provide a suitable environment or behave in a discourteous,
        disrespectful, abusive, or otherwise inappropriate manner towards the Service Providers, they
        reserve the right to refuse to perform the services.
      
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        We also reserve the right to take appropriate action, including withholding access to our services
        and limiting your future access, if we find that you have behaved in a manner that is
        discourteous, disrespectful, abusive, inappropriate, or unlawful towards any service provider.
        Our decisions in such cases are made at our absolute discretion.
      
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        You agree that in the event a Service Provider(s) behaves in a manner that is discourteous,
        disrespectful, abusive, inappropriate or in violation of the user, you shall be required to report
        such incident to <a
        target="_blank"
        style={{ color: "blue", fontWeight: "normal" }}
      >info.hmmtechpvtltd@gmail.com</a> at the earliest but in any event within 24 hours
        of the incident. We will take appropriate action against this after investigating the matter.      
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 11: PRIVACY AND USAGE OF COOKIES
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Homminterior will not intentionally disclose any personally identifying information about you to
        third parties, except where Homminterior, in good faith, believes such disclosure is necessary to
        comply with the law or enforce these Terms. By using the Website, you signify your acceptance
        of Homminterior’s Privacy Policy.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Users signing up for the Website are opting in to receive newsletters and other special offers
        through emails/notifications from the Website. If you do not wish to receive these emails, you
        may opt-out anytime. Please refer to our Privacy Policy.
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 12: INTELLECTUAL PROPERTY RIGHTS
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        "Intellectual Property Rights" means any and all rights existing from time to time under patent
        law, copyright law, moral rights law, trade secret law, trade mark law, unfair competition law,
        publicity rights law, privacy rights law, and any and all other proprietary rights, as well as, any
        and all applications, renewals, extensions, restorations and reinstatements thereof, now or
        hereafter in force and effect worldwide.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        All material and content on the Website, including images, illustrations, text, graphics, logos,
        button icons, images, audio clips, digital downloads, data compilations and software, is our
        property, or the property of our affiliates or content suppliers, and is protected by the domestic as
        well as international intellectual property law, including copyright, authors’ rights, database
        rights laws, trademarks, and other intellectual property rights that are owned and controlled by us
        or by other parties that have licensed their material to us.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        The compilation of all content on the Website is our exclusive property and is protected by
        domestic as well as international copyright and database rights laws.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        You hereby agree to not copy, reproduce, republish, upload, post, transmit or distribute such
        material in any way, including by e-mail or other electronic means whether directly or indirectly
        and you must not assist any other person to do so. Without the prior written consent of the owner,
        modification of the materials, use of the materials on any other Website or use of the materials
        for any purpose other than personal, non-commercial use is a violation of the copyrights,
        trademarks and other proprietary rights, and is prohibited.
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 13: REVIEWS AND FEEDBACK
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Homminterior allows the posting of customer reviews for the services you have purchased and
        the Service Provider you have hired from the Website. We love hearing from our customers! So,
        if you would like to share your thoughts about our Services, please feel free to leave a review on
        our website. By submitting a review, you acknowledge that your comments may be used on our
        website or in our marketing materials. We reserve the right to edit or remove any reviews that
        contain inappropriate or offensive language. Any customer failing to comply with the Terms may
        be expelled and refused continued access to, the ability to post reviews in the future.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Under no circumstances will we, our subsidiaries, affiliates, officers, directors, agents,
        co-branders, other partners, employees, or representatives be liable for any loss or damage
        caused by your reliance on information obtained through these posted comments. The opinions
        expressed by anyone other than the Company itself are solely the opinions of those customers
        and do not reflect the opinions of the Company or any of its subsidiaries or affiliates.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        You also acknowledge and agree that any feedback, including, but not limited to, suggestions,
        comments, ideas, or other information, provided by you in the form of email or other
        submissions (collectively, “Feedback”) to us is non-confidential and you hereby grant
        Company, its affiliates, subsidiaries, successors, and assigns an irrevocable, worldwide,
        transferable, nonexclusive, royalty-free, and fully sublicensable right to use such Feedback for
        any purpose without any compensation or attribution to you.
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 14: WEBSITE SECURITY
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        You agree to use this website only in accordance with these Terms. In the event that your
        unauthorised use of this website results in loss or damage to any person who then brings a claim
        against us, you agree to indemnify us for all losses and/or damages arising from such claim.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        As a user of this website you undertake:
        </Text>
      </Box>
      <Box w={"70%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Flex gap={"5px"} mb={"15px"}>
        <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
          1. 
        </Text>
        <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
            Not use our website in any way that causes or may cause damage to the website or
            impairment of the availability or accessibility of the website; or in any way that is
            unlawful, illegal, fraudulent, harmful, or in connection with any unlawful, illegal,
            fraudulent, or harmful activity or purpose;
        </Text>
        </Flex>

        <Flex gap={"5px"} mb={"15px"}>
        <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
          2. 
        </Text>
        <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
            Not use our website to copy, store, host, transmit, send, use, publish or distribute any
            material that consists of (or is linked to) any spyware, virus, Trojan horse, worm,
            keystroke logger, rootkit, or other malicious computer software;
        </Text>
        </Flex>

        <Flex gap={"5px"} mb={"15px"}>
        <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
          3. 
        </Text>
        <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
        Not conduct any systematic or automated data collection activities, including without
        limitation scraping, data mining, data extraction, and data harvesting on or in relation to
        our website without our express written consent;
        </Text>
        </Flex>

        <Flex gap={"5px"} mb={"15px"}>
        <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
          4. 
        </Text>
        <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
          Not to knowingly or recklessly contravene, in the course of using this website, the
          provisions of any legal or regulatory requirements of any competent authority having
          jurisdiction over you or over any activity you undertake;
        </Text>
        </Flex>

        <Flex gap={"5px"} mb={"15px"}>
        <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
          5. 
        </Text>
        <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
        Not to use this website to make unauthorised attempts to access or interfere with any of
        our systems or third-party networks;
        </Text>
        </Flex>

        <Flex gap={"5px"} mb={"15px"}>
        <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
          6. 
        </Text>
        <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
        Not to use this website to conduct any business or activity or solicit the performance of
        any activity that is prohibited by law;
        
        </Text>
        </Flex>

        <Flex gap={"5px"} mb={"15px"}>
        <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
          7. 
        </Text>
        <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
        Not to use this website for the transmission or posting of any material which is
        defamatory, offensive or of an abusive or obscene or menacing nature or which infringes
        third-party rights, or for the purpose of causing annoyance, inconvenience or needless
        anxiety to any third party, or send any message which you know to be false or make use
        of this website for such purpose(s);

        </Text>
        </Flex>

        <Flex gap={"5px"} mb={"15px"}>
        <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
          8. 
        </Text>
        <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
        to inform us immediately of any claim or action against you for any use of this website
        and, on request from us, to immediately cease the act complained of.        
        </Text>
        </Flex>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Homminterior is entitled to investigate occurrences that may involve such violations and may
        involve, and cooperate with, law enforcement authorities in prosecuting users who are involved
        in such violations.
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 15: DISCLAIMER OF WARRANTIES
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        HOMMINTERIOR MAKE NO REPRESENTATION OR WARRANTY OF ANY KIND WITH
        RESPECT TO THE MATERIALS AND INFORMATION CONTAINED ON THIS WEBSITE.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        YOU USE THIS WEBSITE AT YOUR SOLE RISK (INCLUDING BUT NOT LIMITED TO
          ANY DAMAGE TO YOUR COMPUTER SYSTEM, LOSS OF DATA, DAMAGE
          RESULTING FROM RELIANCE ON INFORMATION FROM THIS WEBSITE, OR OTHER
          DAMAGES THAT RESULT FROM OBTAINING ANY CONTENT FROM THE WEBSITE
          INCLUDING COMPUTER VIRUSES) EVEN IF HOMMINTERIOR HAS BEEN ADVISED
          OF OR IS AWARE OF THE RISK OF SUCH DAMAGE. TO THE FULLEST EXTENT
          PERMITTED BY APPLICABLE LAW, HOMMINTERIOR PROVIDES THIS WEBSITE, THE
          SERVICES, THE INFORMATION AND THE CONTENT “AS IS,” “AS AVAILABLE,” AND
          “WITH ALL FAULTS,” WITHOUT WARRANTY OF ANY KIND, AND HOMMINTERIOR
          SPECIFICALLY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING
          BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTIBILITY, FITNESS
          FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        YOUR SOLE AND EXCLUSIVE REMEDY FOR ISSUES RELATING TO THE USE OF, OR
        THE MATERIAL ON, THE WEBSITE SHALL BE TO DISCONTINUE ACCESSING THE
        WEBSITE AND CEASE FROM USING THE INFORMATION OR MATERIAL OBTAINED.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        YOU AGREE THAT HOMMINTERIOR SHALL NOT BE LIABLE FOR ANY DAMAGES
        RELATING TO YOUR USE OF, OR RELIANCE UPON THE WEBISTE OR ANY OF ITS
        CONTENT.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        NO INFORMATION OR ADVICE PROVIDED ON THIS WEBSITE BY HOMMINTERIOR,
        OR BY HOMMINTERIOR’S EMPLOYEES OR AGENTS, SHALL CREATE ANY
        WARRANTY.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        YOU AGREE AND ACKNOWLEDGE THAT SOLICITING OR RECEIVING SERVICES
        FROM ANY SERVICE PROVIDER INDEPENDENTLY IS SOLELY AT YOUR OWN RISK,
        AND IN SUCH EVENT, YOU WAIVE ANY RIGHTS THAT YOU MAY HAVE UNDER
        THESE TERMS.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        HOMMINTERIOR MAKES NO WARRANTY, REPRESENTATION, COVENANT, OR
        GUARANTEE WHATSOEVER, EXPRESS OR IMPLIED:
        </Text>
      </Box>
      <Box w={"70%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Flex gap={"5px"} mb={"15px"}>
          <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
            1. 
          </Text>
          <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
            AS TO THE VALUE, QUALITY, TIMELINESS, USEFULNESS, RELIABILITY,
            SECURITY, SUITABILITY, TRUTHFULNESS, OR COMPLETENESS OF THIS
            WEBSITE, THE SERVICES, THE INFORMATION, OR THE CONTENT;
          </Text>
        </Flex>
        <Flex gap={"5px"} mb={"15px"}>
          <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
            2. 
          </Text>
          <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
          THAT THIS WEBSITE WILL OPERATE UNINTERRUPTED OR ERROR-FREE;
          </Text>
        </Flex>
        <Flex gap={"5px"} mb={"15px"}>
          <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
            3. 
          </Text>
          <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
          THAT THIS WEBSITE, THE SERVICES, THE INFORMATION, OR THE CONTENT
          WILL MEET YOUR NEEDS OR EXPECTATIONS;
          </Text>
        </Flex>
        <Flex gap={"5px"} mb={"15px"}>
          <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
            4. 
          </Text>
          <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
          AS TO THE QUALITY OR VALUE OF ANY OF HOMMINTERIOR’S PRODUCTS,
          SERVICES, CONTENT, INFORMATION, OR OTHER MATERIAL YOU OBTAIN
          VIA THE WEBSITE; OR
          </Text>
        </Flex>
        <Flex gap={"5px"} mb={"15px"}>
          <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
            5. 
          </Text>
          <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
          THAT ANY ERRORS ON THIS WEBSITE WILL BE CORRECTED.
          </Text>
        </Flex>
      </Box>
      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 16: THIRD-PARTY WEBSITE DISCLAIMER
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        By accessing and using any third-party websites, you acknowledge and agree that Homminterior
        is not responsible for the content, accuracy, or safety of these external websites. You further
        understand that the Terms, privacy policies, and any other guidelines governing these websites
        are solely the responsibility of the respective third parties.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        You agree to read and thoroughly review the policies of any third-party website before accessing
        or utilizing their services. It is your sole responsibility to understand and comply with their terms
        and conditions. Any interactions, transactions, or relationships formed with third-party websites
        are solely between you and the respective third party, and Homminterior shall not be liable for
        any damages or losses arising from such interactions.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Homminterior strongly advises users to exercise caution and use their discretion when accessing
        third-party websites. Your use of these websites is entirely at your own risk, and Homminterior
        disclaims any responsibility for the consequences of your actions or reliance on the information,
        services, or products provided by these external websites.
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 17: LIMITATION OF LIABILITY
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        To the fullest extent permitted by applicable law, Homminterior, its subsidiaries and affiliates,
        and their respective officers, directors, employees, agents, successors and assigns, shall have no
        liability, relating to Your use of or access to (or inability to access) this Website, your use of any
        information or material contained therein, for:
        </Text>
      </Box>
      <Box w={"70%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Flex gap={"5px"} mb={"15px"}>
          <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
            1. 
          </Text>
          <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
          Any and all claims for damages for actual, consequential, incidental, exemplary, special,
          or punitive damages even if Homminterior is advised of the possibility of such
          (including, but not limited to, loss of business, profits, business information, or business
          interruption, or any other pecuniary loss); or
          </Text>
        </Flex>
        <Flex gap={"5px"} mb={"15px"}>
          <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
            2. 
          </Text>
          <Text fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
          For direct damages, actually proven, exceeding INR 5,000 (Rupees Five Thousand). This
          limitation shall be enforced even if it causes an exclusive remedy to fail in its essential
          purpose.
          </Text>
        </Flex>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Homminterior reserves the right, at any time, in the Company’s sole and exclusive discretion, to
        amend, modify, suspend, or terminate this Website, any services, information or content, or any
        part thereof, and/or Your use of or access to them, with or without notice. Homminterior shall
        have no liability to You or any other person or entity for any modification, suspension, or
        termination, or any loss of related information.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, IN NO EVENT WILL
        HOMMINTERIOR BE LIABLE TO YOU OR ANY OTHER PARTY FOR ANY, AND
        HOMMINTERIOR DISCLAIMS ANY AND ALL, DAMAGES, LOSSES, CLAIMS,
        LIABILITIES, PENALTIES, COSTS, EXPENSES AND ATTORNEYS' FEES, INCLUDING,
        WITHOUT LIMITATION, DIRECT, SPECIAL, INCIDENTAL, PUNITIVE, INDIRECT,
        CONSEQUENTIAL OR EXEMPLARY DAMAGES IN ANY WAY RELATING TO OR
        ARISING FROM THE USE OR MISUSE OF THE WEBSITE.
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 18: INDEMNIFICATION
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        You agree to defend, indemnify, and hold Homminterior, its officers, directors, employees,
        agents, licensors, and suppliers, harmless from and against any claims, actions or demands,
        liabilities and settlements including without limitation, reasonable legal and related fees and
        expenses, resulting from, or alleged to result from, Your violation of these Terms or Your use of
        this Website.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Homminterior reserves the right to assume exclusive control of its defence in any matter subject
        to Your indemnification, which shall not excuse Your obligation to indemnify Homminterior.
        You shall not settle any dispute subject to Your indemnification under these Terms without
        written consent from Homminterior.
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 19: GOVERNING LAW AND JURISDICTION
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        These Terms and any separate agreements whereby we provide you Services shall be governed
        by and construed in all respects in accordance with the Laws of India and the courts at New
        Delhi, India shall have exclusive supervisory jurisdiction over all matters in connection with any
        such dispute or any lawsuit arising from or related to this Agreement.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        The rights and duties created shall be interpreted and enforced in India according to the Laws of
        India. You and the Company consent to jurisdiction and venue in New Delhi, India for any
        lawsuit arising from or related to this Agreement.
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 20: NOTICES
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Legal notices must be served on the email address provided in the ‘Contact Us’ clause. Notice
        will be deemed given 48 hours after the email is sent unless the sending party is notified that the
        email address is invalid or that the email has not been delivered. Alternatively, we may give you
        legal notice by mail to the address provided by you during the registration process. In such case,
        notice will be deemed given three days after the date of mailing.
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 21: DISPUTE RESOLUTION
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        If a dispute arises between you and the Company, our goal is to provide you with a neutral and
        cost-effective means of resolving the dispute quickly. We strongly encourage you to first contact
        us to seek a resolution. If your dispute is not resolved by contacting us, all legal notices and
        formal disputes should be sent to the Company’s email ID in accordance with the
        above-mentioned Clause.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Any dispute, controversy, claim or breach arising out of or in relation to this Agreement,
        including any questions, issues or disputes concerning its existence, validity or termination, shall
        be referred to arbitration in accordance with the Arbitration and Conciliation Act, 1996, of India.
        The arbitral tribunal shall consist of 1 (One) arbitrator appointed by Homminterior. The
        arbitration shall be conducted in the Hindi language in New Delhi, India. Depending on the
        consent of the parties, the arbitrator may decide to hold proceedings at any other location. Each
        party shall bear its own costs in relation to the arbitration proceedings, including legal fees,
        expenses, and any other costs incurred. Unless otherwise agreed, the parties shall be responsible
        for their respective costs and expenses associated with the arbitration process.
        
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        The parties involved in the arbitration process shall maintain strict confidentiality and shall not
        disclose any information related to the arbitration proceedings to any person, except on a
        need-to-know basis or to their legal advisors. Confidentiality shall be upheld unless disclosure is
        required by law.
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 22: REMOVAL OF DOUBTS
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Notwithstanding anything stated in this Agreement for any unforeseen issues arising, and not
        covered by this regulation, or in the event of differences of interpretation, the CEO of the
        Company may take a decision as he/she may deem fit. The decision of the CEO shall be final.
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 23: MISCELLANEOUS
        </Text>
      </Box>
      <Box w={"70%"} m={"auto"} textAlign={"left"} mt={"10px"} fontWeight={"normal"} lineHeight={"30px"} letterSpacing={"1px"}>
          <Flex gap={"10px"} mt={"20px"} fontWeight={"bold"}>
            <Text>1.</Text>
            <Text>Severability</Text>
          </Flex>
          <Box pl={"24px"}>
            <Text>
            The provision or part-provision of this Agreement is or becomes invalid, illegal or
            unenforceable, it shall be deemed modified to the minimum extent necessary to make it
            valid, legal and enforceable. If such modification is not possible, the relevant provision or
            part-provision shall be deemed deleted. Any modification to or deletion of a provision or
            part-provision under this clause shall not affect the validity and enforceability of the rest
            of this, agreement. If any provision or part-provision of this Agreement is invalid, illegal
            or unenforceable, the parties shall negotiate in good faith to amend such provision so that
            as amended, It is legal, valid and enforceable, and, to the greatest extent possible,
            achieves the intended commercial result of the original provision.
            </Text>
          </Box>
          <Flex gap={"10px"} mt={"20px"} fontWeight={"bold"}>
            <Text>2.</Text>
            <Text>Entire Agreement</Text>
          </Flex>
          <Box pl={"24px"}>
            <Text>
            The failure of us to exercise or enforce any right or provision of these Terms and
            Conditions shall not constitute a waiver of such right or provision. These Terms and
            Conditions and any policies or operating rules posted by us on this site or in respect to the
            Service constitute the entire agreement and understanding between you and us and
            govern your use of the Service, superseding any prior or contemporaneous agreements,
            communications and proposals, whether oral or written, between you and us. Any
            ambiguities in the interpretation of these Terms and Conditions shall not be construed
            against the drafting party.
            </Text>
          </Box>
          <Flex gap={"10px"} mt={"20px"} fontWeight={"bold"}>
            <Text>3.</Text>
            <Text>Waiver</Text>
          </Flex>
          <Box pl={"24px"}>
            <Text>
            If you breach these Terms and we take no action, we will still be entitled to use our rights
            and remedies in any other situation where you breach these Terms.
            </Text>
          </Box>
          <Flex gap={"10px"} mt={"20px"} fontWeight={"bold"}>
            <Text>4.</Text>
            <Text>Amendments</Text>
          </Flex>
          <Box pl={"24px"}>
            <Text>
            Notwithstanding anything contained hereinbefore, Homminterior may amend and
            implement the Terms, whenever required, in the interest of maintaining the standard and
            improving user experience without any prior notice and you shall be governed by such
            Terms so implemented from time to time. Please review the Terms from time to time on a
            regular basis since your ongoing use is subject to the Terms as amended. Regularly
            checking and reviewing this page ensures that you are updated on the terms and
            conditions governing your use of the Services.
            </Text>
          </Box>
          <Box pl={"24px"}>
            <Text>
            If we believe that the modifications are material, we will notify you of the changes by
            posting a notice on our Website, or emailing you at the email address provided to us by
            you, and as we may deem appropriate. What constitutes a material change will be
            determined by us, at our sole and absolute discretion.
            </Text>
          </Box>
          <Flex gap={"10px"} mt={"20px"} fontWeight={"bold"}>
            <Text>5.</Text>
            <Text>Force Majeure</Text>
          </Flex>
          <Box pl={"24px"}>
            <Text>
            No one shall be liable for any delay or failure in performance due to events outside the
            defaulting Party's reasonable control, including without limitation acts of God,
            earthquake, riots, war, acts of terrorism, fire, epidemics, or delays of common carriers or
            other circumstances beyond its reasonable control.
            </Text>
          </Box>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 24: TERM AND TERMINATION
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        The obligations and liabilities of the parties incurred prior to the termination date shall survive
        the termination of this agreement for all purposes. These Terms and Conditions are effective
        unless and until terminated by either you or us. You may terminate these Terms and Conditions
        at any time by notifying us that you no longer wish to use our Services, or when you cease using
        our Website.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        If in our sole judgment, you fail, or we suspect that you have failed, to comply with any term or
        provision of these Terms and Conditions, we also may terminate this agreement at any time
        without notice and you will remain liable for all amounts due up to and including the date of
        termination; and/or accordingly may deny you access to our Services (or any part thereof).
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Upon termination of this Agreement for any reason: (a) the Users’ rights to use the Services will
        cease immediately, the registration with Us and the Account of the User will cease to apply, and
        We may block the User's access to the Website; (b) the User or Service Provider must pay to the
        Company all amounts due and owing to the Company at the date of termination, owed prior to
        the date of termination; (c) the Parties must cease acting in a manner that would imply a
        continuing relationship between the Parties and all activities contemplated under this Agreement.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        The following Sections will survive termination of this Agreement together with any other terms
        of this Agreement which by their nature do so: Privacy, Intellectual Property Rights, Indemnities,
        Liability, Termination and Governing Law and Dispute Resolution.
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        Termination of this Agreement will not prejudice any rights of the Parties that may have accrued
        prior to such termination.
        </Text>
      </Box>

      <Box pt={"10px"} w={"75%"} m={"auto"}>
        <Text
          textAlign={"left"}
          fontWeight={"bold"}
          fontSize={"20px"}
          textDecoration={"underline"}
        >
        SECTION 25: CONTACT US
        </Text>
      </Box>
      <Box w={"75%"} m={"auto"} textAlign={"left"} mt={"10px"}>
        <Text fontWeight={"light"} lineHeight={"30px"} letterSpacing={"1px"}>
        After reviewing this policy, if you have any additional questions, concerning these Terms and
        Conditions, please contact us by sending an email to <a 
        style={{ color: "blue", fontWeight: "normal" }}>info.hmmtechpvtltd@gmail.com</a> by adding
        the word “Terms” in the subject line.
        </Text>
      </Box>
    </Box>
  );
}

export default TermsConditions;
