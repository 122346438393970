import React from "react";
import logo from "../assets/mobileVerify.svg";
import { Box, Button, Image, Input, Text } from "@chakra-ui/react";
import OtpInput from "otp-input-react";
import "../App.css"

function MobileOTPVerify({loading2,otp,setOtp,onOTPVerify,ph}) {
    
  return (
    <>
      <Box p={"20px 20px 70px 20px"}>
        <Box
          w={{ base: "97%", lg: "30%" }}
          m={"auto"}
          p={{base:"50px 40px 50px 40px",lg:"50px 60px 50px 60px"}}
          borderRadius={"20px"}
          boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"}
        >
          <Box>
            <Text
              fontSize={{ base: "15px", lg: "20px" }}
              color={"#5c727d"}
              fontWeight={500}
            >
              Verify Account
            </Text>
          </Box>
          <Box p={"30px"}>
            <Image m={"auto"} w={"100%"} src={logo} />
          </Box>
          <Box>
            <Text
              fontSize={{ base: "10px", lg: "16px" }}
              fontWeight={500}
              mb={"20px"}
            >
              Mobile Verification has successfully done.
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "10px", lg: "16px" }}
              fontWeight={500}
              mb={"20px"}
              color={"grey"}
            >
              We have send the OTP on +{ph} will apply auto to the fields
            </Text>
          </Box>
          <Box pl={"15px"}>
            <OtpInput inputStyles={{
              width:"100%"
            }} className='opt-container' value={otp}
            onChange={setOtp}
            OTPLength={6}
            otpType="number"
            disabled={false}
            autoFocus ></OtpInput>
          </Box>
          <Box mt={"10px"}>
            <Button
              size={{ base: "sm", lg: "" }}
              bg={"#f20505"}
              color={"white"}
              p={"10px 30px 10px 30px"}
              borderRadius={"20px"}
              onClick={onOTPVerify}
              isLoading={loading2}
            >
              Verify
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default MobileOTPVerify;
